<template>

	<div id="user" v-if="user">

		<div id="user-top">

			<div class="profile-picture">
				<div v-if="user.pseudo" :style="'background-image:url(' + require( '@/assets/img/users/' + user.pseudo.toLowerCase() + '.jpg' ) + ')'"></div>
				<img src="@/assets/img/logo-random-ttfl.png" />
			</div>

			<div>

				<div>
					<div class="black" v-if="ranking"><em>#</em>{{ ranking }}</div>
					<div>{{ user.pseudo }}</div>
					<div>MOY. SAISON : <span class="black">{{ avg_picks }} PTS</span></div>
				</div>

				<div>
					<div>MOY. DECK <em>#</em>{{ last_deck_number }} : <span class="black">{{ last_deck_avg }} PTS</span></div>
					<div>MOY. {{ last_month_label }} : <span class="black">{{ last_month_avg }} PTS</span></div>
					<div>TOTAL : <span class="black">{{ last_month_avg }} PTS</span></div>
				</div>

			</div>

		</div>

		<div>

			{{ get_best_pick }}

			<div class="pts-total">
				Total points : 0 PTS
			</div>

			<div class="pick-counts">
				
				<!-- <div
					v-for="( count, key ) in pick_counts.values" 
					v-bind:key="key" 
					class="pick_count" 
				> -->
					<chart-bar 
						v-for="( count, key ) in pick_counts.values" 
						v-bind:key="key" 

						:label="key" 
						:base_width="pick_counts.max" 
						:value="count" 
						:value_text="count" 
					></chart-bar>
				<!-- </div> -->

			</div>
			
		</div>

		<user-chart :user="user"></user-chart>

	</div>

	<!--
	Comparaison moyennes
	Picks >50, 40-50,...
	Comparaison par team
	Comparaison VS
	Tableau joueurs picks
	-->

</template>

<script>

import moment from 'moment'
moment.locale( 'fr' )

import api from '@/api'
import User from '@/class/User.class'
import Tools from '@/class/Tools.class'

export default {

	components	: {

		'chart-bar'	: () => import( '@/components/chart-bar.vue' ), 
		'user-chart'	: () => import( '@/components/user/user-chart.vue' ),

	},

	data	: function() { return {

		user	: false,
		yesterday	: moment().subtract( 1, 'days' ),
		ranking	: 1,
		// last_deck_avg	: 0,
		// month_avg	: 0,

		best_pick	: false,
		low_pick	: false,

		pick_counts	: {
			values	: false,
			max	: 0,
		},

	}},

	mixins	: [],

	methods	: {

		picks_avg	: function() {
		},

		/** ---------------------------------------*\
		*---------------------------------------- **/

		set_pick_counts	: function() {

			var _this	= this

			this.pick_counts.values	= {
				'<20'	: this.user.picks_count( 0, 19 ),
				'<35'	: this.user.picks_count( 20, 34 ),
				'<50'	: this.user.picks_count( 35, 49 ),
				'<65'	: this.user.picks_count( 50, 64 ),
				'65+'	: this.user.picks_count( 64, 150 ),
			}

			Tools.jsonEach( this.pick_counts.values, function( value ) {
				if ( value > _this.pick_counts.max ) _this.pick_counts.max = value
			})

		},

	},

	watch	: {

		user	: {

			handler() {
				this.set_pick_counts()
				
			},
			deep: true,

		}

	},

	computed: {
		
		/** ---------------------------------------*\
		*---------------------------------------- **/

		avg_picks	: function() {

			var picks	= this.user.get_past_picks( this.yesterday )
			var avg	= picks.reduce(( total, pick ) => total + parseInt( pick.score ), 0 ) / picks.length

			return Math.round( avg * 100 ) / 100

		},

		/** ---------------------------------------*\
		*---------------------------------------- **/

		last_deck_number	: function() {

			var ldeck_sunday	= moment().isoWeekday( 1 ).subtract( 1, 'days' )
			var ldeck_monday	= moment( ldeck_sunday ).isoWeekday( 1 )

			return ldeck_monday.diff( moment( window.ttfl_start_day ), 'weeks' ) + 2

		},

		/** ---------------------------------------*\
		*---------------------------------------- **/

		last_deck_avg	: function() {

			var ldeck_sunday	= moment().isoWeekday( 1 ).subtract( 1, 'days' )
			var ldeck_monday	= moment( ldeck_sunday ).isoWeekday( 1 )

			return this.user.get_picks_average( ldeck_sunday, ldeck_monday )

		},

		/** ---------------------------------------*\
		*---------------------------------------- **/

		last_month_label	: function() {

			var name	= moment().subtract( 1,'months' ).startOf( 'month' ).format( 'MMMM' )

			/** Enlève les accents (pour la font Avenger) */
			return name.normalize('NFD').replace( /[\u0300-\u036f]/g, "" )

		},

		/** ---------------------------------------*\
		*---------------------------------------- **/

		last_month_avg	: function() {

			var date_start	= moment().subtract( 1,'months' ).startOf( 'month' )
			var date_end	= moment().subtract( 1,'months' ).endOf( 'month' )

			return this.user.get_picks_average( date_end, date_start )

		},

		/** ---------------------------------------*\
		*---------------------------------------- **/

		get_best_pick	: function() {
			return this.user.get_best_pick( this.yesterday )
		},

	},

	mounted	: function() {

		// this.username	= this.$route.params.username

		// console.log( this.username )

		api.get( 'ttfl_user/datas', {
			pseudo	: this.$route.params.username,
		}).then(( datas ) => {

			// this.user	= datas
			this.user	= new User( datas )

			this.$route.meta.title = this.user.pseudo + ' - Random TTFL'
			// Add a temporary query parameter.
			this.$router.replace({query: {temp: Date.now()}})
			// // Remove the temporary query parameter.
			this.$router.replace({query: {temp: undefined}})

			this.ranking	= this.user.get_ranking( this.yesterday.format( 'YYYY-MM-DD' ) )

			/** ---------------------------------------*\
			 * Entre 0h et 9h30, pas de ranking de
			 * la veille
			*---------------------------------------- **/
			if ( !this.ranking ) {
				this.ranking	= this.user.get_ranking( moment( this.yesterday.valueOf() ).subtract( 1, 'days' ).format( 'YYYY-MM-DD' ) )
			}

			// console.log( this.ranking )
			this.ranking	= this.ranking.value

		})

	},

}

</script>

<style lang="scss" scoped> .keep-spaces { white-space: pre-wrap; }

@import '@/assets/scss/_base';
@import '@/assets/scss/font-avenger';

/** ---------------------------------------*\
*---------------------------------------- **/

#user {
	padding: 35px;
}

/** ---------------------------------------*\
	Header
*---------------------------------------- **/

.container.container-datas::v-deep .container-head,
.group::v-deep .container .container-head {

	align-items: stretch;
	padding: 0;
	padding-right: 15px;

	& > div {
		@include flex_display;
		align-content: stretch;
	}

	& > div > div {
		@include flex_display;
		height: 100%;
		padding: 10px 15px;
		border-right: 2px solid #000;
	}

	div.even {
		background-color: darken( #FFF, 7% );
	}

	button.toggle {
		height: 100%;
	}

}

/** ---------------------------------------*\
*---------------------------------------- **/

#user .group::v-deep .container .container-head {

	flex-flow: row nowrap;
	
	& > div > div {
		flex: auto;
		max-width: 50%;
		justify-content: center;
		height: 50px;
		font-size: 1.2em;
		line-height: 1em;
	}

	& > div > div.group-title {

		flex: 170px;
		max-width: 170px;
		padding: 5px;
		background-color: $red;
		@include font_avenger;
		color: #FFF;
		font-size: 1.6em;
		line-height: 1em;

		&.loading {
			animation: loader 1.2s $ease-inout-expo infinite;
			// filter: grayscale( 1 );
		}

		@keyframes loader {

			0% {
				background: $red;
				color: #FFF;
			}

			50% {
				background: transparentize( $red, 1 );
				color: #000;
			}

			100% {
				background: $red;
				color: #FFF;
			}

		}
		
	}

	& > div > div.div-select,
	& > div > div.div-date_picker {
		justify-content: flex-start;
	}

	& > div > div.deck-number {
		flex: 170px;
		max-width: 170px;
	}

	span {
		margin-left: 5px;
		font-size: 1.1em;
		line-height: 1em;
	}

	& > div > div.btn-screenshot {

		padding: 0;
		border-right: 0;

		button,
		button:hover {
			
			border: 0;
			background-color: transparent;
			color: #000;
			font-size: inherit;

		}

		button:hover {
			color: $red;
		}

	}

	.head-actions {
		transition: opacity 300ms linear;
	}

}

/** ---------------------------------------*\
*---------------------------------------- **/

#user-top {

	@include flex_display;

	.profile-picture {

		position: relative;
		width: 225px;
		height: 225px;
		padding-right: 25px;
		padding-bottom: 25px;

		& > div {
			width: 100%;
			height: 100%;
			margin: 0;
			background-size: cover;
			border: 4px solid #000;
		}

		img {
			position: absolute;
			width: 100px;
			right: 0;
			bottom: 0;
		}

	}

	& > div {
		@include flex_display;
		align-items: flex-start;
	}

	& > div > div {

		margin-left: 35px;
		@include font_avenger;
		font-size: 2em;
		line-height: 1.3em;
		color: #FFF;

		em {
			@include font_montserrat;
			font-style: normal;
			font-weight: bold;
			line-height: 0em;
		}

	}

	.black {
		color: #000;
	}

	.red {
		color: $red2;
	}

	.green {
		color: $green;
	}

}

/** ---------------------------------------*\
*---------------------------------------- **/

#user .pick-counts .chart-bar::v-deep {

	& > div {

		@include flex_display;
		flex-flow: row nowrap;

		.chart-bar_label,
		.chart-bar_value {
			width: 50px;
		}

		.bar {
			width: calc( 100% - 100px );
		}

	}

}

</style>